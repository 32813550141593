import ContractSearch from './ContractSearch.vue'
import axios from 'axios'
export default {
    components:{
        ContractSearch
    },
    data(){
        return {
            contractLists:[],
            page:0,
            per_page:10,
            currentPage:1,
            count:0,
        }
    },
    mounted() {
        if(!localStorage.getItem('DDTtoken')){
            this.$alert('您还未登录，请登录', {
                confirmButtonText: '确定',
                callback:action => {
                    if(action == 'confirm'){
                         this.$router.push('/login')
                    }else {
                        this.$message({
                            type: 'info',
                            message: "取消",
                            center:true,
                            showClose: true,
                        });
                        this.$router.push('/')
                    }
                }
            });
        }
    },
    created(){
        this.contractSearch()
    },
    methods:{
        contractSearch(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/PersonalFilesComplete',{
                params:{
                    page: this.page,
                    per_page: this.per_page
                }
            })
            .then(res=>{
                console.log(res);
                if(res.data.status_code == 0){
                    this.contractLists = res.data.data
                    this.count = res.data.count
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.per_page = val
            this.contractSearch()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val
            this.contractSearch()
        },
        // 合同签属-一键下载 ----已签署
        downloadSigned(val){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/contract/DocumentsDownload/'+ val,{
                params:{

                }
            })
            .then(res=>{
                // console.log(res);
                if(res.data.code == 0){
                    window.open(res.data.data.docs[0].fileUrl)
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
    }
}