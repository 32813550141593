<template>
  <div class="ContractSearch">
      <div class="con-search-main">
          <table>
              <tr>
                <th>文件名称</th>
                <th>创建时间</th>
                <th>发起方公司名</th>
                <th>发起方签属状态</th>
                <th>签属方公司名</th>
                <th>签署方签属状态</th>
                <th>签属方签属时间</th>
                <th>操作</th>
              </tr>
              <tr v-for="(item,index) in contractLists" :key="index">
                <td :title="item.file_name">{{item.file_name}}</td>
                <td>{{item.create_time}}</td>
                <td>{{item.party_a_org_name}}</td>
                <td>{{item.party_a_sign_state == 2 ? '签署完成' : item.party_a_sign_state == 3 ? '失败' : item.party_a_sign_state == 4 ? '拒签' : item.party_a_sign_state}}</td>
                <td>{{item.party_b_org_name}}</td>
                <td>{{item.party_b_sign_state == 2 ? '签署完成' : item.party_b_sign_state == 3 ? '失败' : item.party_b_sign_state == 4 ? '拒签' : item.party_b_sign_state}}</td>
                <td>{{item.sign_time}}</td>
                <td>
                    <button type="button" @click="downloadSigned(item.flowId)"><span class="el-icon-download" ></span></button>
                </td>
              </tr>
          </table>
          <p class="null" v-if="contractLists.length == 0">暂无数据~</p>
          <div class="con-search-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count">
                </el-pagination>
          </div>
      </div>
      
  </div>
</template>

<script>
    import ContractSearch from './ContractSearch.js'
    export default ContractSearch
</script>

<style>
    @import './ContractSearch.css';
</style>